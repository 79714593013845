import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import autosize from 'autosize';
import type {
  TaskForm,
  TaskFormValues,
} from 'client-lib/src/lib/controllers/hooks/tasks/types';
import useUpsertTask from 'client-lib/src/lib/controllers/hooks/tasks/useUpsertTask';
import REQUIRED_STATIC_FIELDS from 'client-lib/src/lib/controllers/hooks/tasks/utils/constants';
import type {
  UiLabel,
  TemplateForm,
} from 'client-lib/src/lib/utils/helpers/types';
import i18n from 'i18n-js';
import styled, { ThemeContext } from 'styled-components';

import { clearCreateSection } from '../../actions/createSection';
import TemplateAsyncSelect from '../AsyncSelects/TemplateAsyncSelect';
import {
  closeCreateSectionModal,
  openSnackbar,
  setActiveLoseProgressState,
  setActiveSidebar,
} from '../../actions/general';
import {
  Button,
  Heading4,
  Select,
  SelectDate,
  Span,
  Text,
  TextArea,
  TextInput,
} from '../../elements';
import InputLabel from '../../elements/inputCommonElements/InputLabel';
import {
  type CustomerContact,
  type AppState,
  UserRole,
} from '../../utils/helpers/types';
import GroupAsyncSelect from '../AsyncSelects/GroupAsyncSelect';
import UserAsyncSelect from '../AsyncSelects/UserAsyncSelect';
import SectionFormCard from '../CreateSection/SectionFormCard/SectionFormCard';
import PageCompose from '../CreateSection/Templates/PageCompose';
import UnsavedChanges from '../Modals/UnsavedChanges';
import {
  templateTokenLabelStyle,
  TOKENS,
} from '../Settings/Templates/TemplateForm';
import TokenButton from '../Settings/Templates/TokenButton';
import ContactInput from './ContactInput';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import THEMES from '../../styles/themes/app';

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  padding-top: 10px;
  gap: 24px;
`;

const FooterContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const InputPart = styled.div`
  display: flex;
`;

const InputGroup = styled(InputPart)<{ isBottomRow?: boolean }>`
  flex-direction: column;
  ${({ isBottomRow }) => !isBottomRow && 'margin-bottom: 8px;'}
`;

const ButtonRow = styled(InputPart)`
  display: flex;
  margin-top: 4px;

  * {
    box-sizing: content-box;
  }
`;

const ParentInputError = styled.div`
  .element-input-error-text-container {
    box-sizing: content-box;
  }
`;

const OptionDivider = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const RadioInput = styled.div`
  flex: 0 1 336px;
  padding: 28px;
  border-right: 1px solid ${THEMES.BORDER_COLOR};
`;

const RadioTable = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 32px 18px;
  align-items: flex-start;
`;

const RadioRow = styled.div`
  display: contents;

  > label,
  > input {
    cursor: pointer;
  }
`;

const ReactiveInput = styled.div`
  flex: 1;
  padding: 12px 20px;
`;

const LabelTextGroup = styled.label`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const MultipleContactsContainer = styled.div`
  display: flex;
`;

const MultipleContactsInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 10px;
`;

enum ContactOption {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

interface UpsertTaskProps {
  initialFormValues?: TaskFormValues | null;
  taskRecipientFromApi?: CustomerContact | null;
  templateMessageFromApi?: string | null;
}

const UpsertTask = ({
  initialFormValues = null,
  taskRecipientFromApi = null,
  templateMessageFromApi = null,
}: UpsertTaskProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const client = useApolloClient() as any;
  const dispatch = useDispatch();
  const history = useHistory();
  const styledTheme = useContext(ThemeContext);

  const accountName = useSelector((state: AppState) => {
    return state?.accountData?.account?.name;
  });
  const bulkCreateTasksFeatureAvailable = useSelector(
    (state: AppState) => state?.accountData?.account?.ff_bulk_create_tasks
  );
  const emailFeatureAvailable = useSelector(
    (state: AppState) => state?.accountData?.account?.ff_email
  );
  const currentUser = useSelector(
    (state: AppState) => state?.session?.currentUser
  );

  const userCanBulkCreateTasks =
    bulkCreateTasksFeatureAvailable &&
    [UserRole.ADMINISTRATOR, UserRole.MANAGER].includes(
      ((currentUser?.role || '').toLowerCase() as UserRole) || UserRole.MEMBER
    );

  const [taskRecipient, setTaskRecipient] = useState<CustomerContact | null>(
    taskRecipientFromApi
  );
  const [templateMessage, setTemplateMessage] = useState<string | null>(
    templateMessageFromApi
  );
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [nameTokenBubbleOpen, setNameTokenBubbleOpen] = useState(false);
  const [descriptionTokenBubbleOpen, setDescriptionTokenBubbleOpen] =
    useState(false);
  const [taskIsSubmitting, setTaskIsSubmitting] = useState(false);
  const [selectedContactOption, setSelectedContactOption] = useState(
    ContactOption.SINGLE
  );

  // TODO: Implement select multiple contacts
  const selectedContactIds = [];

  const nameInput = useRef<HTMLInputElement>(null);
  const descriptionInput = useRef<HTMLInputElement>(null);

  const closeModal = () => {
    dispatch(setActiveLoseProgressState(false));
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    dispatch(setActiveSidebar('default'));
    history.push('/tasks');
  };

  const { updateField, fields, onSubmit, isVisitedForm } = useUpsertTask({
    client,
    initialFormValues,
    translatedRequiredMessage: i18n.t('slideouts-CreateCustomerForm-required'),
    i18n,
    handleOnSuccess: () => {
      setTaskIsSubmitting(false);
      dispatch(
        openSnackbar(
          initialFormValues
            ? i18n.t('tasks-update-success')
            : i18n.t('tasks-create-success'),
          'success'
        )
      );
      closeModal();
    },
    handleOnError: () => {
      setTaskIsSubmitting(false);
      dispatch(
        openSnackbar(i18n.t('settings-ProfileFormContainer-error'), 'error')
      );
    },
    setIsSubmitting: () => {
      setTaskIsSubmitting(true);
    },
  });

  useEffect(() => {
    if (descriptionInput?.current) {
      autosize(descriptionInput.current);
    }
  }, []);

  useEffect(() => {
    if (descriptionInput?.current) {
      autosize.update(descriptionInput.current);
    }
  }, [fields.description.value]);

  const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    updateField({ name: 'name', value, otherProps: {} });
  };

  const handleDateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    updateField({ name: 'dueDate', value, otherProps: {} });
  };

  const handleDescriptionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    updateField({ name: 'description', value, otherProps: {} });
  };

  const handleGroupIdChange = (value: UiLabel) => {
    updateField({ name: 'group', value, otherProps: {} });
    updateField({
      name: 'assignee',
      value: {
        value: '',
        label: 'Unassigned',
      },
      otherProps: {},
    });
  };

  const handleContactChange = (value: CustomerContact | null) => {
    setTaskRecipient(value);
    updateField({ name: 'recipient', value, otherProps: {} });
  };

  const handleAssigneeChange = (value: UiLabel) => {
    updateField({ name: 'assignee', value, otherProps: {} });
  };

  const handleTemplateChange = (value: TemplateForm) => {
    setTemplateMessage(value.message);
    updateField({ name: 'template', value, otherProps: {} });
  };

  const handleChannelTypeChange = (ev: UiLabel) => {
    const { value } = ev;

    updateField({ name: 'channelType', value, otherProps: {} });
  };

  const handleTokenSelection = (
    name: string,
    input: HTMLInputElement | null,
    token: string,
    setBubbleOpen: (setTo: boolean) => void
  ) => {
    if (!input) {
      return;
    }

    const selectionStart = input.selectionStart || 0;
    const beforeSelection = input.value.slice(0, selectionStart);
    const afterSelection = input.value.slice(selectionStart);

    updateField({
      name,
      value: `${beforeSelection}${token} ${afterSelection}`,
      otherProps: {},
    });

    setBubbleOpen(false);
    setTimeout(() => input.focus(), 0);
  };

  const closeOrShowNotice = () => {
    if (isVisitedForm) {
      setTemplateModalOpen(true);

      return;
    }

    closeModal();
  };

  const setTokenBubbleOrToggle = (
    setFunction: React.Dispatch<React.SetStateAction<boolean>>,
    optionalState?: boolean
  ) => {
    if (optionalState) {
      setFunction(optionalState);

      return;
    }

    setFunction((prev) => !prev);
  };

  const setNameTokenBubble = (optionalState?: boolean) => {
    setTokenBubbleOrToggle(setNameTokenBubbleOpen, optionalState);
  };

  const setDescriptionTokenBubble = (optionalState?: boolean) => {
    setTokenBubbleOrToggle(setDescriptionTokenBubbleOpen, optionalState);
  };

  const userCanSave = useMemo(() => {
    if (!isVisitedForm) {
      return false;
    }

    const requiredFields: (keyof TaskForm)[] =
      selectedContactOption === ContactOption.SINGLE
        ? [...REQUIRED_STATIC_FIELDS, 'recipient']
        : REQUIRED_STATIC_FIELDS;

    const selectedFields =
      selectedContactOption === ContactOption.SINGLE
        ? (fields ?? {})
        : Object.fromEntries(
            Object.entries(fields ?? {}).filter(([key]) => key !== 'recipient')
          );

    const allRequiredFieldsFilled = requiredFields.every((key) => {
      if (key === 'group') {
        return (selectedFields as TaskForm)?.[key]?.value?.value;
      }

      return (selectedFields as TaskForm)?.[key]?.value;
    });

    const anyFieldHasError =
      (selectedFields &&
        Object.values(selectedFields as { error: string }[]).some(
          (field) => field.error !== ''
        )) ||
      (selectedContactOption === ContactOption.MULTIPLE &&
        selectedContactIds.length === 0);

    return allRequiredFieldsFilled && !taskIsSubmitting && !anyFieldHasError;
  }, [
    fields,
    isVisitedForm,
    selectedContactIds.length,
    selectedContactOption,
    taskIsSubmitting,
  ]);

  const populateTemplatePreview = () => {
    if (templateMessage)
      return (
        <Span
          customStyle={() =>
            `font-size: ${FONTSIZE_THEMES.METATEXT}; padding-top: 8px`
          }
        >
          <Span style={{ fontWeight: 600 }}>
            {i18n.t('tasks-create-message-template-preview')}{' '}
          </Span>
          {templateMessage
            .replaceAll('{FIRST_NAME}', taskRecipient?.firstName ?? ' ')
            .replaceAll('{LAST_NAME}', taskRecipient?.lastName ?? ' ')
            .replaceAll('{COMPANY_NAME}', taskRecipient?.account?.name ?? ' ')
            .replaceAll('{ACCOUNT_NAME}', accountName)
            .replaceAll('{GROUP_NAME}', fields?.group?.value?.label)
            .replaceAll('{AMOUNT_DUE}', ' ')
            .replaceAll('{INVOICE_NUMBER}', ' ')}
        </Span>
      );
    return null;
  };

  const handleContactOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event?.target?.value as ContactOption;

    if (value) {
      setSelectedContactOption(value);
    }
  };

  const handleSelectMultipleContacts = () => {
    // TODO: Implement select multiple contacts
    console.log('Select multiple contacts');
  };

  return (
    <PageCompose
      translationKey={
        !initialFormValues ? 'tasks-view-buttonCreate' : 'tasks-edit-header'
      }
      translationFallback={!initialFormValues ? 'Create New Task' : 'Edit Task'}
      footerContent={
        <FooterContentContainer>
          <Button
            dataTestId="upsert-task-cancel-button"
            type="tertiary"
            onClick={closeOrShowNotice}
          >
            {i18n.t('slideouts-EditSaveCancel-cancel')}
          </Button>
          <Button
            dataTestId="upsert-task-save-button"
            disabled={!userCanSave}
            onClick={onSubmit}
          >
            {!initialFormValues
              ? i18n.t('tasks-create-buttonCreate')
              : i18n.t('modals-DocumentForm-submit')}
          </Button>
        </FooterContentContainer>
      }
      isFixed
    >
      <ContentContainer>
        <SectionFormCard
          avatarIconKey="task"
          headerKey="tasks-create-sectionDetailsHeader"
          subHeaderKey="tasks-create-sectionDetailsSubHeader"
          headerKeyFallback="Task Details"
          subHeaderKeyFallback="Provide the essential details about the task, including a name, description, and due date. This will help guide the team member completing the task."
        >
          <InputGroup>
            <TextInput
              dataTestId="upsert-task-name-input"
              label={i18n.t('tasks-general-name')}
              placeholder={i18n.t('tasks-create-formName')}
              error={fields.name.error}
              value={fields.name.value}
              onChange={handleNameChange}
              ref={nameInput}
              hideBottomSpace
              isRequired
              {...(fields.name.error && { title: fields.name.error })}
            />
            <ButtonRow>
              <TokenButton
                labelStyle={templateTokenLabelStyle}
                optionBubbleOpen={nameTokenBubbleOpen}
                tokens={TOKENS}
                handleTokenSelection={(token) =>
                  handleTokenSelection(
                    'name',
                    nameInput.current,
                    token,
                    setNameTokenBubble
                  )
                }
                setOptionBubbleOrToggle={setNameTokenBubble}
                fixedPosition
              />
            </ButtonRow>
          </InputGroup>
          <InputGroup>
            <ParentInputError>
              <SelectDate
                dataTestId="upsert-task-date-input"
                label={i18n.t('tasks-general-date')}
                placeholder={i18n.t('tasks-create-formDate')}
                error={fields.dueDate.error}
                value={fields.dueDate.value}
                onChange={handleDateChange}
                locale={i18n.locale}
                calendarProps={{ maxDate: null, minDate: new Date() }}
                max="9999-12-31"
                allowPlaceholder
                hideBottomSpace
                isRequired
              />
            </ParentInputError>
          </InputGroup>
          <InputGroup>
            <TextArea
              dataTestId="upsert-task-description-input"
              label={i18n.t('tasks-general-description')}
              placeholder={i18n.t('tasks-create-formDescription')}
              error={fields.description.error}
              value={fields.description.value}
              onChange={handleDescriptionChange}
              id="upsert-task-description"
              name="upsert-task-description"
              rows={3}
              ref={descriptionInput}
              customTextAreaStyle={() => `
                max-height: calc(100vh - 80px);
              `}
              hideBottomSpace
            />
            <ButtonRow>
              <TokenButton
                labelStyle={templateTokenLabelStyle}
                optionBubbleOpen={descriptionTokenBubbleOpen}
                tokens={TOKENS}
                handleTokenSelection={(token) =>
                  handleTokenSelection(
                    'description',
                    descriptionInput.current,
                    token,
                    setDescriptionTokenBubble
                  )
                }
                setOptionBubbleOrToggle={setDescriptionTokenBubble}
              />
            </ButtonRow>
          </InputGroup>
          <InputGroup isBottomRow>
            <InputPart>
              <InputLabel error={fields.group.error} isRequired>
                {i18n.t('tasks-general-associatedGroup')}
              </InputLabel>
            </InputPart>
            <GroupAsyncSelect
              value={fields?.group?.value}
              setValue={handleGroupIdChange}
              error={fields.group.error}
              placeholder={i18n.t('tasks-general-associatedGroup-placeholder')}
              queryVars={{ taskEnabled: true }}
              noComponents={undefined}
              omitIds={[]}
              omitAll
              hideBottomSpace
              skipPermissionCheck
            />
          </InputGroup>
        </SectionFormCard>
        {userCanBulkCreateTasks ? (
          <SectionFormCard
            avatarIconKey="contact"
            headerKey="tasks-bulkCreate-contacts"
            subHeaderKey="tasks-bulkCreate-contactsSectionDescription"
            headerKeyFallback="Contact(s)"
            subHeaderKeyFallback="Select contact(s) for this outreach task. Choose a Single Contact or Multiple Contacts to create an individual task for each."
            noBodyPadding
          >
            <InputGroup isBottomRow>
              <OptionDivider>
                <RadioInput>
                  <RadioTable>
                    <RadioRow>
                      <input
                        id="contact-single"
                        style={{
                          accentColor: THEMES.FOREGROUND_HIGH_COLOR({
                            theme: styledTheme,
                          }),
                        }}
                        type="radio"
                        value={ContactOption.SINGLE}
                        checked={selectedContactOption === ContactOption.SINGLE}
                        onChange={handleContactOptionChange}
                      />
                      <LabelTextGroup htmlFor="contact-single">
                        <Heading4>
                          {i18n.t('tasks-bulkCreate-singleContactOption', {
                            defaultValue: 'Single Contact',
                          })}
                        </Heading4>
                        <Text>
                          {i18n.t('tasks-bulkCreate-selectContact', {
                            defaultValue:
                              'Select on contact to be the subject of this outreach task',
                          })}
                        </Text>
                      </LabelTextGroup>
                    </RadioRow>
                    <RadioRow>
                      <input
                        id="contact-multiple"
                        style={{
                          accentColor: THEMES.FOREGROUND_HIGH_COLOR({
                            theme: styledTheme,
                          }),
                        }}
                        type="radio"
                        value={ContactOption.MULTIPLE}
                        checked={
                          selectedContactOption === ContactOption.MULTIPLE
                        }
                        onChange={handleContactOptionChange}
                      />
                      <LabelTextGroup htmlFor="contact-multiple">
                        <Heading4>
                          {i18n.t('tasks-bulkCreate-multipleContactsOption', {
                            defaultValue: 'Multiple Contacts',
                          })}
                        </Heading4>
                        <Text>
                          {i18n.t('tasks-bulkCreate-selectMultipleContacts', {
                            defaultValue:
                              'Select multiple contacts to create an individual outreach task for each one.',
                          })}
                        </Text>
                      </LabelTextGroup>
                    </RadioRow>
                  </RadioTable>
                </RadioInput>
                <ReactiveInput>
                  {selectedContactOption === ContactOption.SINGLE ? (
                    <ContactInput
                      contact={fields.recipient.value}
                      error={fields.recipient.error}
                      setContact={handleContactChange}
                      translatedLabel={i18n.t('slideouts-CreateThread-toLabel')}
                      hideBottomSpace
                      isRequired
                    />
                  ) : (
                    <MultipleContactsContainer>
                      <MultipleContactsInput>
                        <Text>
                          {i18n.t('tasks-bulkCreate-noneSelected', {
                            defaultValue: 'None Selected',
                          })}
                        </Text>
                        <Button
                          size="lg"
                          type="secondaryLight"
                          onClick={handleSelectMultipleContacts}
                        >
                          {i18n.t('tasks-bulkCreate-selectContacts', {
                            defaultValue: 'Select Contacts',
                          })}
                        </Button>
                      </MultipleContactsInput>
                    </MultipleContactsContainer>
                  )}
                </ReactiveInput>
              </OptionDivider>
            </InputGroup>
          </SectionFormCard>
        ) : (
          <SectionFormCard
            avatarIconKey="contact"
            headerKey="tasks-create-recipient"
            subHeaderKey="tasks-create-recipient-details"
            headerKeyFallback="Recipient"
            subHeaderKeyFallback="Select or enter the recipient who will be the subject of this outreach task."
          >
            <InputGroup isBottomRow>
              <ContactInput
                contact={fields.recipient.value}
                error={fields.recipient.error}
                setContact={handleContactChange}
                translatedLabel={i18n.t('slideouts-CreateThread-toLabel')}
                hideBottomSpace
                isRequired
              />
            </InputGroup>
          </SectionFormCard>
        )}
        {fields?.group?.value && (
          <>
            <SectionFormCard
              avatarIconKey="user"
              headerKey="tasks-create-assignTo"
              subHeaderKey="tasks-create-assignTo-description"
            >
              <InputGroup isBottomRow>
                <InputPart>
                  <InputLabel error={fields.group.error}>
                    {i18n.t('tasks-create-assignTo')}
                  </InputLabel>
                </InputPart>
                <UserAsyncSelect
                  value={fields.assignee.value}
                  setValue={handleAssigneeChange}
                  isClearable={fields.assignee.value.value !== ''}
                  error={fields.assignee.error}
                  onClear={() =>
                    updateField({
                      name: 'assignee',
                      value: { value: '', label: 'Unassigned' },
                      otherProps: {},
                    })
                  }
                  queryVars={{
                    groupIds: [fields.group.value.value],
                    includeAccountScopedUsers: true,
                  }}
                  placeholder={i18n.t('tasks-create-assignTo-placeholder')}
                  hideBottomSpace
                />
              </InputGroup>
            </SectionFormCard>

            <SectionFormCard
              avatarIconKey="template"
              headerKey="tasks-create-template"
              subHeaderKey="tasks-create-template-description"
            >
              {emailFeatureAvailable && (
                <InputGroup>
                  <InputPart>
                    <InputLabel error={fields.group.error}>
                      {i18n.t('tasks-create-message-type')}
                    </InputLabel>
                  </InputPart>
                  <Select
                    options={[
                      {
                        value: 'SMS',
                        label: i18n.t('tasks-create-message-selection-sms'),
                      },
                      {
                        value: 'email',
                        label: i18n.t('tasks-create-message-selection-email'),
                      },
                    ]}
                    value={fields?.channelType?.value}
                    placeholder={i18n.t('settings-manageRules-select', {
                      defaultValue: 'Select',
                    })}
                    onChange={handleChannelTypeChange}
                  />
                </InputGroup>
              )}
              <InputGroup isBottomRow>
                <InputPart>
                  <InputLabel error={fields.group.error}>
                    {i18n.t('tasks-create-message-template-label')}
                  </InputLabel>
                </InputPart>
                <TemplateAsyncSelect
                  value={fields?.template?.value}
                  setValue={handleTemplateChange}
                  onClear={() => {
                    updateField({
                      name: 'template',
                      value: { value: '', label: '' },
                      otherProps: {},
                    });
                  }}
                  isClearable
                  placeholder={i18n.t('settings-manageRules-selectMessage', {
                    defaultValue: 'Select Message',
                  })}
                  queryVars={{
                    groupIds: [fields.group.value.value],
                    channelTypeFilter: [
                      fields.channelType.value === 'email' ? 'EMAIL' : 'SMS',
                    ],
                  }}
                  error={fields.template.error}
                  hideBottomSpace
                />
              </InputGroup>
              {populateTemplatePreview()}
            </SectionFormCard>
          </>
        )}
      </ContentContainer>
      <UnsavedChanges
        isOpen={templateModalOpen}
        handleClose={closeModal}
        handleCancel={() => {
          setTemplateModalOpen(false);
        }}
      />
    </PageCompose>
  );
};

export default UpsertTask;
