import {
  ApolloClient,
  useMutation,
  type OperationVariables,
} from "@apollo/client";
import moment from "moment";

import CREATE_TASK_MUTATION from "../../../graphql/mutation/CreateTaskMutation.graphql";
import UPDATE_TASK_MUTATION from "../../../graphql/mutation/UpdateTaskMutation.graphql";
import TASKS_QUERY from "../../../graphql/query/TasksQuery.graphql";
import type { I18n } from "../../../utils/helpers/types";
import useForm from "../../../utils/hooks/useForm";
import type { TaskForm, TaskFormValues, TaskFormValuesToDb } from "./types";
import REQUIRED_STATIC_FIELDS from "./utils/constants";

interface UseUpsertTaskProps {
  client: ApolloClient<object>;
  initialFormValues?: TaskFormValues | null;
  translatedRequiredMessage: string;
  i18n: I18n;
  handleOnSuccess: () => void;
  handleOnError: (error: unknown) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

const useUpsertTask = ({
  client,
  initialFormValues = null,
  translatedRequiredMessage,
  i18n,
  handleOnSuccess,
  handleOnError,
  setIsSubmitting,
}: UseUpsertTaskProps) => {
  const options: OperationVariables = { ...client };
  const creatingTask = !initialFormValues?.id;
  const [createTask] = creatingTask
    ? useMutation(CREATE_TASK_MUTATION, options)
    : useMutation(UPDATE_TASK_MUTATION, options);

  const requiredValidation = {
    type: "required",
    errorMessage: translatedRequiredMessage,
  };

  const taskNameCharMax = 75;

  const taskNameMaxLengthValidation = {
    type: "maxLength",
    maxLength: taskNameCharMax,
    errorMessage: i18n.t("tasks-error-nameTooLong", {
      charMax: taskNameCharMax,
      defaultValue: `Task name must be ${taskNameCharMax} characters or less.`,
    }),
    optional: true,
  };

  const defaultFormValues: TaskFormValues = {
    description: "",
    assignee: {
      value: "",
      label: "Unassigned",
    },
    dueDate: "",
    group: null,
    name: "",
    recipient: null,
    template: null,
    channelType: "SMS",
  };

  const initialValues = {
    description: {
      value: initialFormValues?.description || defaultFormValues.description,
    },
    dueDate: {
      value: initialFormValues?.dueDate
        ? moment(initialFormValues.dueDate).format("YYYY-MM-DD")
        : defaultFormValues.dueDate,
      validations: [requiredValidation],
    },
    group: {
      value: initialFormValues?.group || defaultFormValues.group,
      validations: [requiredValidation],
    },
    name: {
      value: initialFormValues?.name || defaultFormValues.name,
      validations: [requiredValidation, taskNameMaxLengthValidation],
    },
    recipient: {
      value: initialFormValues?.recipient || defaultFormValues.recipient,
      validations: [requiredValidation],
    },
    assignee: {
      value: initialFormValues?.assignee || defaultFormValues.assignee,
    },
    template: {
      value: initialFormValues?.template
        ? {
            value: initialFormValues.template.id,
            label: initialFormValues.template.name,
          }
        : defaultFormValues.template,
    },
    channelType: {
      value: initialFormValues?.channelType || defaultFormValues.channelType,
    },
    ...(creatingTask ? {} : { id: { value: initialFormValues?.id || "" } }),
  };

  const { fields, updateField, onSubmit, isVisitedForm } = useForm(
    initialValues,
    async (currentFields: TaskForm) => {
      const isLackingRequiredFields = REQUIRED_STATIC_FIELDS.some(
        (field) => !currentFields[field]?.value
      );

      if (isLackingRequiredFields) {
        return;
      }

      const assigneeId =
        currentFields.assignee.value?.value === ""
          ? null
          : currentFields.assignee.value?.value;

      const variables: TaskFormValuesToDb = {
        description: currentFields.description.value,
        dueDate: moment(currentFields.dueDate.value)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
        groupId: currentFields.group.value.value,
        name: currentFields.name.value,
        recipientId: currentFields.recipient?.value?.id || "",
        templateId: currentFields.template?.value?.value || null,
        channelType: currentFields.channelType?.value || "",
        assigneeId,
        ...(currentFields.id && !creatingTask
          ? { id: currentFields.id.value }
          : {}),
      };

      setIsSubmitting(true);

      try {
        const { data } = await createTask({
          variables,
          refetchQueries: [TASKS_QUERY],
          onQueryUpdated: (observableQuery) => {
            return observableQuery.refetch();
          },
        });
        if (data?.createLabel?.errors) {
          handleOnError(data?.createLabel?.errors);
        } else {
          handleOnSuccess();
        }
      } catch (error) {
        handleOnError(error);
      }
    }
  );

  return {
    updateField,
    fields,
    onSubmit,
    isVisitedForm,
  };
};

export default useUpsertTask;
